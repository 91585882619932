import IngredientService from 'services/IngredientService.js'

export const namespaced = true

export const state = {
  ingredient: {}
}

export const mutations = {
  SET_INGREDIENT(state, ingredient) {
    state.ingredient = ingredient
  }
}

export const actions = {
  fetchIngredient({ commit, getters, dispatch, state }, {id, service}) {
    return new Promise((resolve, reject) => {
      new IngredientService.getIngredient({id, service})
      .then(response => {
        commit('SET_INGREDIENT', response.data)
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
    })
  }
}
