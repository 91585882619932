export const namespaced = true

export const state = {
  fav_resources: [],
  cart_resources: []
}

export const mutations = {
  CART_ADD(state, payload){
    state.cart_resources.push(payload);
  },
  CART_REMOVE(state, index){
    state.cart_resources.splice(index, 1);
  },
  CART_RESET(state){
    state.cart_resources = [];
  },
  FAV_ADD(state, payload){
    state.fav_resources.push(payload);
  },
  FAV_REMOVE(state, index){
    state.fav_resources.splice(index, 1);
  },
  FAV_RESET(state){
    state.fav_resources = [];
  }
}

export const actions = {
  addToCart({ state ,commit, dispatch }, payload) {
    let index = state.cart_resources.indexOf(payload);
    if (index === -1) {
      commit('CART_ADD', payload);  
    } else {
      if (payload.confirm) {
        // $t('cart.remove_are_you_sure')
        if (confirm('¿Está seguro que desea quitar este recurso de la selección?')){
          commit('CART_REMOVE', index);
        }
      } else {
        commit('CART_REMOVE', index)
      }
    }
    dispatch('updateCartLocalStorage');
  },
  addToFav({ state, commit, dispatch }, payload) {
    // payload = {id, type}
    return new Promise((resolve, reject) => {
      let index = state.fav_resources.indexOf(payload)
      if (index === -1) {
        commit('FAV_ADD', payload)
      } else {
        if (payload.confirm) {
          // $t('cart.remove_are_you_sure')
          if (confirm('¿Está seguro que desea quitar este recurso de la selección?')){
            commit('FAV_REMOVE', index)
          }
        } else {
          commit('FAV_REMOVE', index)
        }
      }
      dispatch('updateFavLocalStorage')
      resolve()
    })
  },
  resetCart({ commit }, payload){
    commit('CART_RESET');
    localStorage.removeItem('Cart_resources');
  },
  resetFav({ commit }, payload){
    commit('FAV_RESET');
    localStorage.removeItem('Fav_resources');
  },
  updateFavLocalStorage({ state }, payload){
    localStorage.setItem('Fav_resources', JSON.stringify(state.fav_resources));
  },
  updateCartLocalStorage({ state }, payload){
    localStorage.setItem('Cart_resources', JSON.stringify(state.cart_resources));
  }
}

export const getters = {
  
}
