<template>
  <div>
    <infinite-loading v-if="nextPageAvailable" @infinite="nextPage">
      <div slot="no-more">No more data :)</div>
      <div slot="no-results"></div>
    </infinite-loading>
    <div v-else>
      <div class="no-more-results">{{ $t('search.no_more') }}</div>
    </div>  
  </div>
  
</template>

<script>
  import i18n from '../plugins/i18n'
  import store from '../store/store'
  import { mapGetters } from 'vuex'
  import InfiniteLoading from 'vue-infinite-loading'

  export default {
    name: 'Pagination',
    i18n,
    components: {
      InfiniteLoading
    },
    props: {
      list: {
        type: Object,
        required: true
      }
    },
    computed: mapGetters('list', ['nextPageAvailable']),
    methods: {
      nextPage($state){
        store.dispatch('list/fetchListNextPage', {id: this.list.id, warehouse: this.list.warehouse}).then(response => {
          if (this.nextPageAvailable) {
            $state.loaded()
          // } else  {
          //   $state.complete()
          }
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../stylesheets/components/pagination.scss"
</style>
