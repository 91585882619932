<template>
  <div class="ingredient-formats">
    <v-subheader>{{ $t('ingredient.formats') }}</v-subheader>
    <v-simple-table>
      <template v-slot:default>
        <tbody>
          <tr v-for="format in ingredient.formats" :key="format.id">
            <td>{{ format.name }}</td>
            <td>{{ format.conversion }}</td>
            <td class="text-right"><span class="item-sale-price">{{ $n(format.sale_price, 'currency') }}</span></td>
            <td>
              <AddToCart action="Fav" type="icon" :item="ingredient" :formatId="format.id" />
            </td>
            <!--<td>
              <AddToCart action="Cart" type="icon" :itemId="ingredient.id" itemType="Ingredient" :formatId="format.id" v-if="format.sale_price > 0" />
            </td>-->
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
  import i18n from '../plugins/i18n'
  import AddToCart from '../components/AddToCart'

  export default {
    i18n,
    components: {
      AddToCart
    },
    props: {
      ingredient: {
        type: Object
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../stylesheets/components/ingredient_formats.scss"
</style>
