<template>
  <v-container id="search-criterios">
	  <v-row v-for="(criterio, index) in criterios" class="search-criterio-row pa-2" :class="{'last': (index === criterios.length -1)}" :key="`criterio_${index}`">
      <v-col>
        <div class="search-criterio">
          <v-divider v-if="index > 0"></v-divider>
          <span>{{ formattedKey(criterio) }}:</span>
          <span>{{ formattedValue(criterio) }}</span>
          <v-btn v-if="index == criterios.length - 1" color="red" text small @click="removeCriterio(criterio)">
            <v-icon left>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <!-- <v-row v-if="sale_price_gteq">
      <v-col>
        <div class="search-criterio">
          <span>{{ $t('search.sale_price_gteq', {value: sale_price_gteq}) }}</span>
          <v-btn color="red" text small @click="removeSalePriceGteq">
            <v-icon left>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="sale_price_lteq">
      <v-col>
        <div class="search-criterio">
          <span>{{ $t('search.sale_price_lteq', {value: sale_price_lteq}) }}</span>
          <v-btn color="red" text small @click="removeSalePriceLteq">
            <v-icon left>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row> -->
  </v-container>
</template>

<script>
  import i18n from '../plugins/i18n'
  import { mapState } from 'vuex'
  import { mapGetters } from 'vuex'
  import store from '../store/store'

  export default {
    i18n,
    store,
    computed: {
      // , 'sale_price_gteq', 'sale_price_lteq'
      ...mapState('search', ['criterios']),
      ...mapGetters('search', ['getSearchQuery'])
    },
    methods: {
      formattedKey(criterio) {
        return i18n.t('search.facets.' + criterio.key)
      },
      formattedValue(criterio) {
        if (['keyword', 'year'].indexOf(criterio.key) > -1) {
          return criterio.value
        } else if (['sale_price'].indexOf(criterio.key) > -1) {
          return this.$options.filters.to_pretty_price(criterio.value)
        } else {
          return this.$options.filters.to_locale(criterio.value)
        }
      },
      removeCriterio(criterio){
        this.$store.dispatch('search/removeCriterio', criterio).then(_ => {
          this.$router.push("/search?" + this.getSearchQuery);
        })
      },
        // removeSalePriceGteq(){
        //   store.dispatch('search/setSalePrice', [null,this.sale_price_lteq]).then(response => {
        //     this.$router.push("/search?" + this.getSearchQuery);
        //   })
        // },
        // removeSalePriceLteq(){
        //   store.dispatch('search/setSalePrice', [this.sale_price_gteq, null]).then(response => {
        //     this.$router.push("/search?" + this.getSearchQuery);
        //   })
        // }
    }
  }
</script>

<style scoped lang="scss">
  @import "../stylesheets/components/search_criterios.scss"
</style>
