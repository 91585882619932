<template>
  <div class="search-facets">
    <v-card>
      <v-card-title v-if="showTitle" @click="showText = !showText">
        {{ facet.name }}
        <v-icon right v-if="!showText">mdi-arrow-down-drop-circle-outline</v-icon>
        <v-icon right v-else>mdi-arrow-up-drop-circle-outline</v-icon>
      </v-card-title>
      <v-card-text v-if="showText">
        <Facet v-for="one_facet in shownFacets" :facet="one_facet" :type="type" :key="one_facet[0]" class="ma-1" />
        <v-btn v-if="showFacetsDialog" @click="dialog = true" text small class="btn-facets-dialog">
          <v-icon large color="primary">mdi-view-list</v-icon>
          <span class="display-custom-1">Ver todas</span>
        </v-btn>
        <v-dialog v-model="dialog" :fullscreen="this.$vuetify.breakpoint.xsOnly" content-class="facets">
          <v-btn color="grey" text class="dialog-close" @click="dialog = false">
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <div class="dialog-content">
            <div class="dialog-title">{{ facet.name }}</div>
            <Facet v-for="one_facet in facet.facets" :facet="one_facet" :type="type" :key="one_facet[0]" class="ma-1" />
          </div>
        </v-dialog>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import Facet from '../components/Facet'
  import store from '../store/store'
  
  const SHOWN_FACETS = 20;

  export default {
    props: ['facet'],
    components: {
      Facet
    },
    data () {
      return {
        showText: false,
        dialog: false
      }
    },
    computed: {
      type(){
        return this.$vnode.key
      },
      showTitle(){
        return !['family', 'subfamily'].includes(this.type); 
      },
      showFacetsDialog(){
        return (this.facet.facets.length > SHOWN_FACETS)
      },
      shownFacets(){
        if (this.showFacetsDialog) {
          return this.facet.facets.splice(0,SHOWN_FACETS)
        } else {
          return this.facet.facets
        }
      }
    },
    created(){
      this.showText = (this.type === 'sale_price') || !this.showTitle
    }

  }
</script>

<style scoped lang="scss">
  @import "../stylesheets/components/search_facets.scss"
</style>
