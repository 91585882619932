export default {
  computed: {
    bigImage(){
      if (this.coverImage.match(/w300_/)) {
        return this.coverImage.replace(/w300_/, 'w700_')
      } else {
        let ary = this.coverImage.split('/')
        let image = ary.slice(-1).map(x => "w700_" + x)
        return ary.slice(0,-1).concat(image).join('/')
      }
    }
  }
}
