<template>
  <div id="search-form">
    <form id="search-value" @submit.stop.prevent="onSubmit">
      <v-row>
        <v-col>
          <v-text-field flat solo dense clearable autocomplete="off" hide-details color="primary darken-1"
            v-model="search" 
            :label="$t('search.title')">
          </v-text-field>
        </v-col>
        <v-col cols="1" @click="onSubmit">
          <v-btn color="primary">
            <v-icon color="white">mdi-magnify</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="3"></v-col>
      </v-row>
    </form>
  </div>
</template>

<script>
  import i18n from '../plugins/i18n'
  import { mapGetters } from 'vuex'

  export default {
    i18n,
    props: {
      reset: {
        type: Boolean,
        default: false
      }
    },
    data: function(){
      return {
        search: "",
        error: ""
      }
    },
    computed: mapGetters('search', ['getSearchQuery']),
    methods: {
      onSubmit(){
        if (this.search) {
          if (this.reset){
            this.$router.push("/search?key=keyword&value=" + this.search);
          } else {
            let criterio = {key: 'keyword', value: this.search};
            this.$store.dispatch('search/addCriterio', criterio).then(_ => {
              this.$router.push("/search?" + this.getSearchQuery);
            });  
          }
        } else {
          this.$router.push("/search");
        }
      }, 
      resetSearch(){
        this.search = '';
        this.error = ';'
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../stylesheets/components/search_form.scss"
</style>
