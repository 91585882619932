<template>
  <div class="categories">
    <v-subheader>{{ $t('categories.title') }}</v-subheader>
    <v-divider></v-divider>
    <v-container fluid>
    <v-row>
      <v-col v-for="(categories, index) in [evenValues, oddValues]" :key="index" cols="6">
        <div v-for="category in categories" :key="category.id" class="category ma-1" :class="$vuetify.breakpoint.xsOnly ? 'xs' : ''">
          <div class="category-name">{{category.name}}: </div>
          <div class="tags">
            <Facet v-for="tag in category.tags" :key="tag.tag_id" :type="category.key" :facet="[tag.tag_value, 0]" :reset="true" />
          </div>
        </div>
      </v-col>
    </v-row>
    </v-container>
  </div>
</template>

<script>
  import i18n from '../plugins/i18n'
  import Facet from './Facet'

  export default {
    i18n,
    components: {
      Facet
    },
    props: {
      values: {
        type: Array
      }
    },
    computed: {
      oddValues(){
        return this.values.filter((a,i) => i%2 === 1)
      },
      evenValues(){
        return this.values.filter((a,i) => i%2 === 0)
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../stylesheets/components/categories.scss"
</style>
