export default Object.freeze({
  Alemania: "de",
  Argentina: "ar",
  Australia: "aus",
  Austria: "at",
  Barbados: "bb",
  Canada: "ca",
  Chile: "cl",
  Cuba: "cu",
  Escocia: "gb-sct",
  Eslovenia: "si",
  España: "es",
  Estados_Unidos: "us",
  Francia: "fr",
  Georgia: "ge",
  Grecia: "gr",
  Guatemala: "gt",
  Hungría: "hu",
  Inglaterra: "gb-eng",
  Italia: "it",
  Irlanda: "ie",
  Jamaica: "jm",
  Japón: "jp",
  Líbano: "lb",
  Marruecos: "ma",
  Martinica: "mq",
  Mexico: "mx",
  Nicaragua: "ni",
  Nueva_Zelanda: "nz",
  Polonia: "pl",
  Portugal: "pt",
  Puerto_Rico: "pr",
  República_Dominicana: "do",
  Rusia: "ru",
  Santa_Lucía: "lc",
  Sudáfrica: "za",
  Suecia: "se",
  Tailandia: "th",
  Uruguay: "uy",
  Venezuela: "ve",
  Suiza: "ch"
})

// Holanda: "",