import ListService from 'services/ListService.js'
import WarehouseService from 'services/WarehouseService.js'

export const namespaced = true

export const LIST_ITEMS_PER_PAGE = 30

export const state = {
  lists: [], 
  list: {},
  list_items: [],
  list_items_count: 0,
  page: 1,
  loading: false
}

export const mutations = {
  SET_LISTS(state, lists) {
    state.lists = lists
  },
  SET_LIST(state, list) {
    state.list = list
  },
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_LIST_ITEMS(state, payload) {
    state.list_items = payload;
  },
  PUSH_LIST_ITEMS(state, payload) {
    payload.forEach(a => {
      state.list_items.push(a);
    })
  },
  SET_LIST_ITEMS_COUNT(state, payload) {
    state.list_items_count = payload;
  },
  INCREMENT_PAGE(state) {
    state.page += 1;
  },
  SET_PAGE(state, payload){
    state.page = payload;
  }
}

export const actions = {
  fetchLists({ commit, dispatch, state }, type) {
    commit('SET_LOADING', true);
    return new Promise((resolve, reject) => {
      new ListService.getLists(type)
        .then(response => {
          commit('SET_LISTS', response.data)
          commit('SET_LOADING', false)
          resolve(response)
        })
        .catch(error => {
          commit('SET_LOADING', false)
          reject(error)
        })
      })
  },
  fetchList({ state, getters, commit }, {id, warehouse}) {
    return new Promise((resolve, reject) => {
      if (state.page > 0) {
        if (id != state.list.id) {
          commit('SET_PAGE', 1);
        }
        if (state.page === 1) {
          commit('SET_LOADING', true);
        }
        let service = warehouse ? new WarehouseService.getWarehouse({id: id, page: state.page}) : new ListService.getList({id: id, page: state.page})

        service.then(response => {
          if (state.page === 1) {
            commit('SET_LIST_ITEMS', response.data.list_items)
            commit('SET_LIST_ITEMS_COUNT', response.data.list_items_count)
            commit('SET_LIST', Object.assign(response.data, {list_items: [], list_items_count: undefined}))
          } else {
            let list_items = response.data.list_items
            if (list_items.length > 0) {
              commit('PUSH_LIST_ITEMS', list_items)
            } else {
              commit('SET_PAGE', -1);
            }
          }
          commit('SET_LOADING', false);
          resolve(response.data)
        })
        .catch(error => {
          commit('SET_LOADING', false);
          reject(error)
        })
      } else {
        commit('SET_LOADING', false);
      }
    })
  },
  fetchListNextPage({ commit, dispatch }, {id, warehouse}){
    return new Promise((resolve, reject) => {
      commit('INCREMENT_PAGE');
      dispatch('fetchList', {id, warehouse}).then(response => {
        resolve(response);
      });
    });
  }
}

export const getters = {
  getListItems: state => hierarchy => {
    return state.list_items.filter(item => item.hierarchy.slice(0,-1).length === hierarchy.length && item.hierarchy.slice(0,-1).every((v, i) => v === hierarchy[i]))
  },
  nextPageAvailable: state => {
    return (state.list_items_count > state.list_items.length) && (state.page > 0)
  }
}