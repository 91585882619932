<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <div class="list-category-title">
        {{ category.name }}
	      <span v-if="list.list_type === 'free' && list_items.length > 0" class="children-count">({{list_items.length}})</span>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content >
      <ListCategories v-if="category.list_categories" :categories="category.list_categories" :initPanel="[0]" />
      <v-list v-else-if="list_items">
        <ListItem v-for="(item, i) in list_items" :key="item.id" :item="item" :choose="category.choose" />
        <infinite-loading v-if="nextPageAvailable" @infinite="nextPage">
          <div slot="no-more"></div>
          <div slot="no-results"></div>
        </infinite-loading>
      </v-list>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
  import i18n from '../plugins/i18n'
  import ListService from '../services/ListService.js'
  import store from '../store/store'
  import { mapState } from 'vuex'
  import { mapGetters } from 'vuex'
  import InfiniteLoading from 'vue-infinite-loading'
  import ListItem from './ListItem'
  // import Pagination from './Pagination'

  export default {
    name: 'ListCategory',
    i18n,
    components: {
      ListCategories: () => import('./ListCategories'),
      ListItem,
      InfiniteLoading
    },
    props: {
      category: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        infiniteId: 1
      }
    },
    computed: {
      level(){
        return this.hierarchy[0]
      },
      list_items(){
        return this.getListItems(this.category.hierarchy)
      },
      ...mapState('list', ['list']),
      ...mapGetters('list', ['getListItems', 'nextPageAvailable'])
    },
    methods: {
      nextPage($state){
        store.dispatch('list/fetchListNextPage', {id: this.list.id, warehouse: this.list.warehouse}).then(response => {
          if (this.nextPageAvailable) {
            $state.loaded()
          } else  {
            $state.complete()
          }
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../stylesheets/components/list_category.scss"
</style>
