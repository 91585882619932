<template>
  <v-card class="ingredient-card">
    <v-row>
      <v-col v-if="this.coverImage" cols="4">
        <v-img :src="bigImage"></v-img>
      </v-col>

      <v-col cols="8">
        <div class="ingredient-title">
          <v-card-title>
            {{ ingredient.name }}
          </v-card-title>
          <v-card-subtitle v-if="this.hasCategories">
            <country-flag :country="country_code" size='normal'/>
            <span class="origin">{{ origin }}</span>
          </v-card-subtitle>
        </div>

        <IngredientFormats v-if="!this.$vuetify.breakpoint.xsOnly && this.hasFormats" :ingredient="ingredient" />
      </v-col>

      <v-col v-if="this.$vuetify.breakpoint.xsOnly && this.hasFormats" cols="12">
        <IngredientFormats :ingredient="ingredient" />
      </v-col>
    </v-row>
    
    <template v-if="ingredient.description">
      <v-divider></v-divider>  
      <v-card-text>
	      <div v-html="descriptionParagraphs[0]"></div>
        <div v-if="descriptionParagraphs.length > 1">
          <a class="toggle-description" :class="{'d-none': showMoreDescription}" @click="showMoreDescription = !showMoreDescription">{{ $t('read_more') }}</a>
          <v-fade-transition> 
            <div key="more-description" v-if="showMoreDescription" v-html="descriptionParagraphs.slice(1).join('')"></div>
          </v-fade-transition>
          <a class="toggle-description" :class="{'d-none': !showMoreDescription}" @click="showMoreDescription = !showMoreDescription" key="toggle-less-description">{{ $t('read_less') }}</a>
        </div>
      </v-card-text>
    </template>

    <v-divider></v-divider>

    <v-row class="categories-row" v-if="this.hasCategories">
      <v-col><Categories :values="ingredient.categories" /></v-col>
    </v-row>

    <v-container fluid class="ingredient-allergen-nutritional">

    <v-row justify="space-between">
      <v-col v-if="ingredient.allergens.length > 0"><Allergens :values="ingredient.allergens" /></v-col>
      <v-col><NutritionalInfo :values="ingredient.nutritional_info" class="ingredient" container="Ingredient" /></v-col>
    </v-row>
    </v-container>

  </v-card>
</template>

<script>
  import i18n from '../plugins/i18n'
  import countries from '../plugins/countries'
  import store from '../store/store'
  import IngredientFormats from '../components/IngredientFormats'
  import Categories from '../components/Categories'
  import Allergens from '../components/Allergens'
  import NutritionalInfo from '../components/NutritionalInfo'
  import big_image_mixin from '../mixins/big_image'
  import CountryFlag from 'vue-country-flag'

  export default {
    i18n,
    store,
    components: {
      IngredientFormats,
      Categories,
      Allergens,
      NutritionalInfo,
      CountryFlag,
    },
    mixins: [big_image_mixin],
    props: {
      ingredient: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        showMoreDescription: false
      }
    },
    computed: {
      coverImage(){
        return this.ingredient.images[0].url
      },
      hasCategories(){
        return (this.ingredient.categories != undefined) && (this.ingredient.categories.length > 0)
      },
      hasFormats(){
        return (this.ingredient.formats != undefined) && (this.ingredient.formats.length > 0)
      },
      country(){
        let country_category = this.ingredient.categories.find(x => x.key === 'category_16');
        let country_tag = country_category.tags[0]
        return country_tag.tag_name
      },
      country_code(){
        return countries[this.country.replace(/\s/, '_')]
      },
      origin(){
        let origin_category = this.ingredient.categories.find(x => x.key === 'category_15');
        if (origin_category != undefined) {
          return origin_category.tags[0].tag_name
        } else if (this.country != undefined){
          return this.country
        } else {
          return undefined
        }
      },
      descriptionParagraphs(){        
        return this.ingredient.description.split("</p>").filter(a => a.length > 0).map(a => a + '</p>')
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../stylesheets/components/ingredient.scss"
</style>
