<template>
  <v-container fluid class="recipe-show-container without-padding">
    <Recipe :recipe="recipe" />
  </v-container>
</template>

<script>
  import Recipe from '../components/Recipe'

  export default {
    components: {
      Recipe
    },
    props: {
      recipe: {
        type: Object,
        required: true
      }
    }
  }
</script>

<style scoped>
  
</style>
