import RecipeService from 'services/RecipeService.js'

export const namespaced = true

export const state = {
  recipe: {}
}

export const mutations = {
  SET_RECIPE(state, recipe) {
    state.recipe = recipe
  }
}

export const actions = {
  fetchRecipe({ commit, getters, dispatch, state }, id) {
    return new Promise((resolve, reject) => {
      new RecipeService.getRecipe(id)
        .then(response => {
          commit('SET_RECIPE', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
      })
  }
}
