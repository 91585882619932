<template>
	<v-expansion-panels multiple v-model="panel" :class="`level${level}`">
    <ListCategory v-for="(category,i) in categories" :key="category.id" :category="category" />  
  </v-expansion-panels>
</template>

<script>
  import i18n from '../plugins/i18n'

  export default {
    name: 'ListCategories',
    i18n,
    components: {
      // import component dynamically to allow nesting
      ListCategory: () => import('./ListCategory')
    },
    props: {
      categories: {
        type: Array,
        required: true
      },
      initPanel: {
        type: Array, 
        default: () => []
      }
    },
    computed:{
      level(){
        return (this.categories != undefined ? this.categories[0].hierarchy.length : 1)
      }
    },
    data(){
      return {
        panel: this.initPanel
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../stylesheets/components/list_categories.scss"
  @import "../stylesheets/components/list_category.scss"
</style>
