import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const messages = {
  es: {
    currency: {
      style: 'currency', currency: 'EUR'
    },
    app_title: 'Escuela de Hostelería de Leioa',
    apply: 'Aplicar',
    close: 'Cerrar',
    food: 'Comida',
    drink: 'Bebida',
    read_more: 'Leer más',
    read_less: 'Leer menos',
    or_more: 'o más',
    Cart: {
      title: 'Comanda',
      empty: 'Eliminar comanda',
      add: 'Añadir a comanda'
    },
    Fav: {
      title: 'Favoritos',
      add: 'Añadir a favoritos',
      empty: 'Limpiar favoritos',
      empty_text_1: 'Tu lista está vacía',
      empty_text_2: 'Navega por nuestra carta para añadir platos o bebidas a favoritos'
    },
    home: {
      food: 'Platos y menús',
      drink: 'Bodega y bebidas',
      welcome_text_1: 'Bienvenidos a Lukitxene.',
      welcome_text_2: 'Por favor, selecciona por dónde quieres comenzar'
    },
    ingredient: {
      formats: 'Formatos y precios'
    },
    allergens: {
      title: 'Alérgenos',
      legend: 'Leyenda de alérgenos',
      traces: 'Trazas de {allergen}',
      gluten: 'Gluten',
      milk: 'Leche',
      eggs: 'Huevos',
      soy: 'Soja',
      nuts: 'Frutos de cáscara',
      peanuts: 'Cacahuetes',
      sesame: 'Sésamo',
      celery: 'Apio',
      sulfites: 'Sulfitos',
      fish: 'Pescado',
      molluscs: 'Moluscos',
      crustaceans: 'Crustáceos',
      mustard: 'Mostaza',
      lupins: 'Altramuces'
    },
    nutritional_info: {
      title: 'Información Nutricional',
      energy: {
        label: 'Energía',
        value: '{value} kcal',
      },
      proteins: {
        label: 'Proteínas',
        value: '{value} g' 
      },
      carbohydrates: {
        label: 'Carbohidratos',
        value: '{value} g'
      },
      fiber: {
        label: 'Fibra',
        value: '{value} g' 
      },
      lipids: {
        label: 'Grasas',
        value: '{value} g'
      },
      saturated: {
        label: 'Ácidos grasos saturados',
        value: '{value} g'
      },
      monounsaturated: {
        label: 'Ácidos grasos monoinsaturados',
        value: '{value} g'
      },
      polyunsaturated: {
        label: 'Ácidos grasos polinsaturados',
        value: '{value} g'
      },
      cholesterol: {
        label: 'Colesterol',
        value: '{value} mg'
      },
      calcium: {
        label: 'Calcio',
        value: '{value} mg'
      },
      iron: {
        label: 'Hierro',
        value: '{value} mg'
      },
      zinc: {
        label: 'Zinc',
        value: '{value} mg'
      },
      vitamin_a: {
        label: 'Vitamina A',
        value: '{value} ug'
      },
      vitamin_c: {
        label: 'Vitamina C',
        value: '{value} g'
      },
      folic_acid: {
        label: 'Ácido fólico',
        value: '{value} ug'
      },
      salt: {
        label: 'Sal (Sodio)',
        value: '{value} mg'
      },
      sugar: {
        label: 'Azúcares',
        value: '{value} g'
      }
    },
    categories: {
      title: 'Categorías y etiquetas'
    },
    search: {
      drinks: 'En Bodega',
      title: 'Buscar',
      new: 'Nueva búsqueda',
      by_keyword: 'Búsqueda libre',
      show: 'Resultados de búsqueda',
      sale_price: 'Precio de venta',
      searching: 'Buscando...',
      filters: 'Filtra por',
      results: 'Sin resultados | 1 resultado | {count} resultados',
      sale_price_gteq: 'Desde {value} €',
      sale_price_lteq: 'Hasta {value} €',
      criterios: 'Criterios de búsqueda aplicados',
      no_results: 'No se han encontrado resultados',
      no_more: 'No hay más resultados',
      sort: {
        title: 'Ordenar por',
        sale_price_asc: 'Precio de menor a mayor',
        sale_price_desc: 'Precio de mayor a menor',
        score: 'Relevancia'
      },
      facets: {
        keyword: 'Palabra clave',
        family: 'Familia',
        subfamily: 'Tipo',
        year: 'Año',
        format: 'Formato',
        sale_price: 'Precio de venta',
        category_16: 'País',
        category_15: 'Denominación/Región',
        category_25: 'Grado de alcohol',
        category_19: 'Tipo de uva',
        category_20: 'Tipo de vino',
        category_41: 'Prácticas de agricultura o elaboración',
        category_18: 'Tipo de crianza',
        category_21: 'Bodega',
        category_23: 'Productor/Enólogo',
        category_22: 'Características del vino',
        category_24: 'Recomendado para',
        category_26: 'Sulfitos',
        category_44: 'Destilería',
        category_46: 'Tipo de destilación',
        sale_price_gteq: 'PVP desde',
        sale_price_lteq: 'PVP hasta'
      }
    },
    list: {
      show: 'Ver menú',
      show_free: 'Ver carta',
      drink_search_text: '¿Buscas un vino especial?<br /> Explora entre las <strong>más de 1.900 referencias</strong> de nuestra bodega:',
      drink_list_text: '¿Prefieres que te aconsejemos? Descubre <strong>nuestras selecciones</strong>:'
    } ,
    recipe: {
      vegan: 'Apto para veganos',
      vegetarian: 'Apto para vegetarianos',
      season: 'Plato de temporada'
    },
    search_wines_card: {
      title: 'Encuentra tu vino',
      description: '¿Buscas un vino en particular? Nuestro buscador te ayudará a encontrarlo entre las más de 1.900 referencias de nuestra bodega',
      action: 'Buscar en la Bodega'
    }
  },
  eu: {
    currency: {
      style: 'currency', currency: 'EUR'
    },
    app_title: 'Leioako Ostalaritza Eskola',
    apply: 'Aplikatu',
    close: 'Itxi',
    food: 'Janaria',
    drink: 'Edaria',
    read_more: 'Irakurri gehiago',
    read_less: 'Irakurri gutxiago',
    or_more: 'edo gehiago',
    Cart: {
      title: 'Komanda',
      add: 'Gehitu komandan',
      empty: 'Ezabatu komanda'
    },
    Fav: {
      title: 'Gogokoenak',
      add: 'Gehitu gogokoetan',
      empty: 'Garbitu gogokoenak',
      empty_text_1: 'Zure zerrenda hutsik dago',
      empty_text_2: 'Ibil zaitez gure kartatik platerak edo edariak gogokoetara gehitzeko'
    },
    home: {
      food: 'Platerak eta menuak',
      drink: 'Upategia eta edariak',
      welcome_text_1: 'Ongi etorri Lukitxenera.',
      welcome_text_2: 'Mesedez, aukera ezazu nondik hasi nahi duzun'
    },
    ingredient: {
      formats: 'Formatoak eta prezioak'
    },
    allergens: {
      title: 'Alergenoak',
      legend: 'Alergenoen zerrenda',
      traces: '{allergen}-aztarnak',
      gluten: 'Glutena',
      milk: 'Esnea',
      eggs: 'Arrautzak',
      soy: 'Soja',
      nuts: 'Fruitu oskoldunak',
      peanuts: 'Kakahuetea',
      sesame: 'Sesamo-aleak',
      celery: 'Apioa',
      sulfites: 'Sulfitoak',
      fish: 'Arraina',
      molluscs: 'Moluskuak',
      crustaceans: 'Krustazeo',
      mustard: 'Ziapea',
      lupins: 'Eskuzuriak'
    },
    nutritional_info: {
      title: 'Nutrizio-informazioa',
      energy: {
        label: 'Energia',
        value: '{value} kcal'
      },
      proteins: {
        label: 'Proteinak',
        value: '{value} g'
      },
      carbohydrates: {
        label: 'Karbohidratoak',
        value: '{value} g'
      },
      fiber: {
        label: 'Zuntza',
        value: '{value} g'
      },
      lipids: {
        label: 'Koipeak',
        value: '{value} g'
      },
      saturated: {
        label: 'Gantz azido aseak',
        value: '{value} g'
      },
      monounsaturated: {
        label: 'Gantz azido monoasegabeak',
        value: '{value} g'
      },
      polyunsaturated: {
        label: 'Gantz azido poliasegabeak',
        value: '{value} g'
      },
      cholesterol: {
        label: 'Kolesterola',
        value: '{value} mg'
      },
      calcium: {
        label: 'Kaltzioa',
        value: '{value} mg'
      },
      iron: {
        label: 'Burdina',
        value: '{value} mg'
      },
      zinc: {
        label: 'Zinka',
        value: '{value} mg'
      },
      vitamin_a: {
        label: 'A bitamina',
        value: '{value} ug'
      },
      vitamin_c: {
        label: 'C bitamina',
        value: '{value} g'
      },
      folic_acid: {
        label: 'Azido folikoa',
        value: '{value} ug'
      },
      salt: {
        label: 'Gatza (Sodioa)',
        value: '{value} mg'
      },
      sugar: {
        label: 'Azukreak',
        value: '{value} g'
      }
    },
    categories: {
      title: 'Kategoriak eta etiketak'
    },
    search: {
      drinks: 'Upategian',
      title: 'Bilatu',
      new: 'Bilaketa berria',
      sale_price: 'Salmenta prezioa',
      by_keyword: 'Bilaketa librea',
      searching: 'Bilatzen...',
      filters: 'Iragazi',
      results: 'Emaitzarik gabe | emaitza 1 | {count} emaitzak',
      show: 'Bilaketaren emaitzak',
      sale_price_gteq: '{value} €tik',
      sale_price_lteq: '{value} €ra',
      criterios: 'Bilaketa-irizpideak aplikatuta',
      no_results: 'Ez da emaitzarik aurkitu',
      no_more: 'Ez dago emaitza gehiagorik',
      sort: {
        title: 'Zerrendatu',
        sale_price_asc: 'Prezio txikitik handira',
        sale_price_desc: 'Prezio handitik txikira',
        score: 'Garrantzia'
      },
      facets: {
        keyword: 'Gako-hitza',
        family: 'Mota',
        subfamily: 'Azpitaldea',
        year: 'Urtea',
        format: 'Formatua',
        sale_price: 'Salmenta-prezioa',
        category_16: 'Herrialdea',
        category_15: 'Jatorri-deitura/Eskualdea',
        category_25: 'Alkohol-gradua',
        category_19: 'Mahats mota',
        category_20: 'Ardo mota',
        category_41: 'Nekazaritza eta elaborazio teknikak',
        category_18: 'Ontze mota',
        category_21: 'Upategia',
        category_23: 'Ekoizlea/Enologoa',
        category_22: 'Ardoaren ezaugarriak',
        category_24: 'Honetarako gomendatua',
        category_26: 'Sulfitoak',
        category_44: 'Destilategia',
        category_46: 'Destilazio mota',
        sale_price_gteq: 'Salmenta-prezioa €tik',
        sale_price_lteq: 'Salmenta-prezioa €ra'
      }
    },
    recipe: {
      vegan: 'Beganoentzat egokia',
      vegetarian: 'Begetarianoentzat egokia',
      season: 'Plato de temporada*'
    },
    list: {
      show: 'Ikusi menua',      
      show_free: 'Ikusi karta',
      drink_search_text: 'Ardo bereziren bat nahi al duzu?<br /> Begiratu gure upategiko <strong>1900 erreferentziatik</strong> gorako eskaintzak',
      drink_list_text: 'Nahiago al duzu guk laguntzea? Begira itzazu <strong>gure aukerak</strong>:'
    },
    search_wines_card: {
      title: 'Aurkitu zure ardoa',
      description: 'Ardo bereziren bat nahi? Gure bilatzaileak ardo hori aurkitzen lagunduko dizu gure bodegako 1900etik gorako erreferentzien artean.',
      action: 'Bilatu upategian'
    }
  },
  en: {
    currency: {
      style: 'currency', currency: 'EUR'
    },
    app_title: 'Leioa Catering School',
    apply: 'Apply',
    close: 'Close',
    food: 'Food',
    drink: 'Drink',
    read_more: 'Read more',
    read_less: 'Read less',
    or_more: 'or more',
    Cart: {
      title: 'Order',
      add: 'Add to order',
      empty: 'Cancel order'
    },
    Fav: {
      title: 'Favorites',
      add: 'Add to favorites',
      empty: 'Delete favorites',
      empty_text_1: 'Your favorites list is empty.',
      empty_text_2: 'Browse our menus to add food or beverages to favorites.'
    },
    home: {
      food: 'Dishes and menus',
      drink: 'Our wines and drinks',
      welcome_text_1: 'Welcome to Lukitxene.',
      welcome_text_2: 'Please, select from where you would like to start'
    },
    ingredient: {
      formats: 'Formats & prices'
    },
    allergens: {
      title: 'Allergens',
      legend: 'Allergens legend',
      traces: 'Traces of {allergen}',
      gluten: 'Gluten',
      milk: 'Milk',
      eggs: 'Eggs',
      soy: 'Soy',
      nuts: 'Nuts',
      peanuts: 'Peanuts',
      sesame: 'Sesame',
      celery: 'Celery',
      sulfites: 'Sulfites',
      fish: 'Fish',
      molluscs: 'Molluscs',
      crustaceans: 'Crustaceans',
      mustard: 'Mustart',
      lupins: 'Lupins'
    },
    nutritional_info: {
      title: 'Nutritional information',
      energy: {
        label: 'Energy',
        value: '{value} kcal'
      },
      proteins: {
        label: 'Proteins',
        value: '{value} g'
      },
      carbohydrates: {
        label: 'Carbohydrates',
        value: '{value} g'
      },
      fiber: {
        label: 'Fiber',
        value: '{value} g'
      },
      lipids: {
        label: 'Lipids',
        value: '{value} g'
      },
      saturated: {
        label: 'Saturates',
        value: '{value} g'
      },
      monounsaturated: {
        label: 'Monounsaturated fatty acids',
        value: '{value} g'
      },
      polyunsaturated: {
        label: 'Polyunsaturated fatty acids',
        value: '{value} g'
      },
      cholesterol: {
        label: 'Cholesterol',
        value: '{value} mg'
      },
      calcium: {
        label: 'Calcium',
        value: '{value} mg'
      },
      iron: {
        label: 'Iron',
        value: '{value} mg'
      },
      zinc: {
        label: 'Zinc',
        value: '{value} mg'
      },
      vitamin_a: {
        label: 'Vitamin A',
        value: '{value} ug'
      },
      vitamin_c: {
        label: 'Vitamin C',
        value: '{value} g'
      },
      folic_acid: {
        label: 'Folic acid',
        value: '{value} ug'
      },
      salt: {
        label: 'Salt (Sodium)',
        value: '{value} mg'
      },
      sugar: {
        label: 'Sugars',
        value: '{value} g'
      }
    },
    categories: {
      title: 'Categories & tags'
    },
    search: {
      drinks: 'Winery',
      new: 'New search',
      sale_price: 'Price',
      show: 'Search results',
      title: 'Search',
      searching: 'Searching...',
      by_keyword: 'Search by keyword',
      filters: 'Filter by',
      results: 'No results found | 1 result | {count} results',
      sale_price_gteq: 'From {value} €',
      sale_price_lteq: 'To {value} €',
      criterios: 'Search criteria applied',
      no_results: 'No results found',
      no_more: 'No more results',
      sort: {
        title: 'Sort by',
        sale_price_asc: 'Price from lowest to highest',
        sale_price_desc: 'Price from highest to lowest',
        score: 'Relevance'
      },
      facets: {
        keyword: 'Keyword',
        family: 'Family',
        subfamily: 'Type',
        year: 'Year',
        format: 'Format',
        sale_price: 'Price',
        category_16: 'Country',
        category_15: 'Designation of origin/Region',
        category_25: 'Alcohol content',
        category_19: 'Grape variety',
        category_20: 'Wine type',
        category_41: 'Production methods',
        category_18: 'Type of aging',
        category_21: 'Winery',
        category_23: 'Producer/Oenologist',
        category_22: 'Wine characteristics',
        category_24: 'Recommended',
        category_26: 'Sulphites',
        category_44: 'Distillery',
        category_46: 'Types of distillation',
        sale_price_gteq: 'Price from',
        sale_price_lteq: 'Price to'
      }
    },
    recipe: {
      vegan: 'Suitable for vegans',
      vegetarian: 'Suitable for vegetarians',
      season: 'Plato de temporada*'
    },
    list: {
      show: 'See menu',
      show_free: 'See A la carte menu',
      drink_search_text: 'Would you like to taste a special wine?<br />Search among <strong> more than 1,900 references </strong> in our  winery:',
      drink_list_text: 'Would you like some wine recommendations? Discover <strong>our selections.</strong>:'
    },
    search_wines_card: {
      title: 'Find your wine',
      description: 'Are you looking for a special wine ?Our search engine will help you find it from over 1,900 references  of our winery.',
      action: 'Search our winery'
    }
  }
}

const numberFormats = {
  es: {
    currency: {
      style: 'currency', currency: 'EUR'
    }
  }
}

const availableLocales = ['en', 'es', 'eu']

export default new VueI18n({
  locale: 'es', 
  fallbackLocale: 'es',
  messages,
  numberFormats
});
