// import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"

// Rails.start()
// Turbolinks.start()

import Vue from 'vue';
import vuetify from '../plugins/vuetify'
import i18n from '../plugins/i18n'
import router from '../router'
import VuePageTransition from 'vue-page-transition'

import App from './App.vue';

import '../stylesheets/application.scss';
// import 'nprogress/nprogress.css'

String.prototype.capitalize = function() {
  return this.charAt(0).toUpperCase() + this.slice(1);
}

Vue.filter('to_locale', function(value) {
  let index = i18n.availableLocales.indexOf(i18n.locale)
  let fallback_index = i18n.availableLocales.indexOf(i18n.fallbackLocale)
  return value.split('|')[index].length > 0 ? value.split('|')[index] : value.split('|')[fallback_index]
})

Vue.filter('to_pretty_price', function(value) {
  let a = value.split(' - ')
  let b = Math.round(parseFloat(a[1]))
  let c = b ? ('- ' + b + '.0€') : i18n.t('or_more')
  return a[0] + ' ' + c
})

Vue.use(i18n);
Vue.use(vuetify);
Vue.use(VuePageTransition);

document.addEventListener('DOMContentLoaded', () => {
  const app = new Vue({
    i18n,
    router,
    vuetify,
    render: h => h(App)
  }).$mount('#app');
});
