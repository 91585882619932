<template>
  <v-list class="allergens" :class="targetView">
    <template v-if="targetView != 'ListItem'">
      <v-subheader v-if="targetView === 'Legend'">{{ $t('allergens.legend') }}</v-subheader>
      <v-subheader v-else>{{ $t('allergens.title') }}</v-subheader>
      <v-divider></v-divider>
    </template>
    <v-list-item-group>
      <v-list-item v-for="allergen in values" :key="allergen" class="ma-2" label inactive>
        <v-img contain :src="`/images/allergens/${allergen}.svg`" :width="$vuetify.breakpoint.xsOnly ? '40' : '60'" />
        <template v-if="allergen.match(/_traces/) != null">
          <span v-if="targetView != 'ListItem'">{{ $t('allergens.traces', {allergen: $t('allergens.' + allergen.replace(/_traces/, ''))}) }}</span>
        </template>  
        <template v-else>
          <span v-if="targetView != 'ListItem'">{{ $t('allergens.' + allergen) }}</span>
        </template>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
  import i18n from '../plugins/i18n'
  export default {
    i18n,
    props: {
      values: {
        type: Array
      },
      targetView: {
        type: String,
        default: 'Show'
        // Show for recipes, ingredients
        // ListItem for list_items
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../stylesheets/components/allergens.scss"
</style>
