<template>
  <v-chip color="primary" text-color="white" label @click="onClick">
    <span class="value">{{ formattedValue }}</span>
    <span v-if="count" class="count ml-1"> ({{ count }})</span>
  </v-chip>
  <!-- <v-chip label @click="onClick">
    <span class="value">{{ formattedValue }}</span>
    <span class="count"> ({{ count }})</span>
  </v-chip> -->
</template>

<script>
  import i18n from '../plugins/i18n'
  import { mapGetters } from 'vuex'
  import store from '../store/store'

  export default {
    i18n,
    store,
    props: ['facet', 'type', 'reset'],
    computed: {
      value(){
        return this.facet[0]
      },
      escaped_value(){
        return encodeURIComponent(this.value)
      },
      count(){
        return this.facet[1]
      },
      formattedValue(){
        if (this.value.match(/\|/) != null) {
          return this.$options.filters.to_locale(this.value)
        } else if (this.value.match(/€/) != null) {
          return this.$options.filters.to_pretty_price(this.value)
        } else {
          return this.value;
        }
      },
      ...mapGetters('search', ['getParentTitle'])
    },
    methods: {
      onClick(){
        let query = ["key=" + this.type, "value=" + this.escaped_value]
        
        if (!this.reset) {
          let parent_title = this.getParentTitle
          if (parent_title.length > 0) {
            query = query.concat("parent_title=" + parent_title)
          }
        }
        
        this.$router.push("/search?" + query.join('&'));
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../stylesheets/components/facet.scss"
</style>
