import Vue from 'vue';
import Vuex from 'vuex';
import * as cart from './modules/cart.js'
import * as list from './modules/list.js'
import * as recipe from './modules/recipe.js'
import * as ingredient from './modules/ingredient.js'
import * as search from './modules/search.js'

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    cart,
    list,
    recipe,
    ingredient,
    search
  },
  state: {

  }
})