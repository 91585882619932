<template>
  <v-skeleton-loader :loading="loading" class="mx-auto" type="text,chip,chip,chip">
    <div class="search-fields">
      <div class="search-aside-title">{{ $t('search.filters') }}:</div>

      <!--<v-card class="price-search" v-if="sliderRange[0] != sliderRange[1]">
        <v-card-title>{{ $t('search.sale_price') }}:</v-card-title>
        <v-card-text>
          <v-range-slider v-model="sliderRange" :min="min_sale_price" :max="max_sale_price" hide-details class="align-center" track-color="white" track-fill-color="primary darken-1">
            <template v-slot:prepend>
              <v-text-field :value="sliderRange[0]" class="mt-0 pt-0" hide-details single-line
                type="number" style="width: 60px" @change="changeMinSliderRange" append-icon="mdi-currency-eur"></v-text-field>
            </template>
            <template v-slot:append>
              <v-text-field :value="sliderRange[1]" class="mt-0 pt-0" hide-details single-line
                type="number" style="width: 60px" @change="changeMaxSliderRange" append-icon="mdi-currency-eur"></v-text-field>
            </template>
          </v-range-slider>
          <v-btn @click="applySalePriceAndSearch" color="primary">{{ $t('apply') }}</v-btn>
        </v-card-text>
      </v-card>-->

      <div class="search-facets">
        <SearchFacets v-for="facet in facets" :key="facet.type" :facet="facet"/>
      </div>

      <v-card class="search-form">
        <v-card-title>{{ $t('search.by_keyword') }}</v-card-title>
        <SearchForm />
      </v-card>
      
    </div>
  </v-skeleton-loader>
</template>

<script>
  import SearchForm from '../components/SearchForm'
  import SearchFacets from '../components/SearchFacets'
  import i18n from '../plugins/i18n'
  import store from '../store/store'
  import { mapState } from 'vuex'
  
  export default {
    props: ['facet'],
    components: {
      SearchForm,
      SearchFacets
    },
    // data(){
    //   return {
    //     slider_gteq: null,
    //     slider_lteq: null,
    //   }
    // },
    computed: {
      // sliderRange: {
      //   get(){
      //     return [this.slider_gteq || this.min_sale_price, this.slider_lteq || this.max_sale_price ]
      //   },
      //   set(value){
      //     this.slider_gteq = value[0]
      //     this.slider_lteq = value[1]
      //   }
      // },
      // 'sale_price_gteq', 'sale_price_lteq', 'min_sale_price', 'max_sale_price'
      ...mapState('search', ['facets', 'loading']),
    },
    methods: {
      // changeMinSliderRange(event){
      //   this.sliderRange = [Number(event), this.slider_lteq];
      // },
      // changeMaxSliderRange(event){
      //   this.sliderRange = [this.slider_gteq, Number(event)];
      // },
      // applySalePriceAndSearch(){
      //   store.dispatch('search/setSalePrice', this.sliderRange).then(response => {
      //     this.$router.push("/search?" + this.getSearchQuery);
      //   });
      // }
    }
  }
</script>

<style scoped lang="scss">
  @import "../stylesheets/views/search_show.scss"
</style>
