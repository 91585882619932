<template>
  <v-app id="inspire">
    <v-navigation-drawer v-model="drawer" app clipped class="primary darken-1">
	    <v-container class="hidden-lg-and-up mt-2 pt-2 pb-2">
        <v-row>
          <v-col cols="2"><v-btn icon @click.stop="drawer = !drawer"><v-icon color="white">mdi-close</v-icon></v-btn></v-col>
          <v-col><v-img src="/images/lukitxene_logo_inverse.svg" :alt="$t('app_title')" class="site-logo" width="160" contain></v-img></v-col>
        </v-row>
      </v-container>
      <v-list class="options">
        <v-list-item class="sidebar-list-item locales">
          <v-list-item-action></v-list-item-action>
          <v-list-item-content>
            <v-list dense class="locales">
              <v-list-item v-for="lang in langs" :key="lang" @click="changeLocale(lang)" :class="{active: $root.$i18n.locale == lang}" >
                <v-list-item-content>
                  <v-list-item-title>{{ lang }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
	        </v-list-item-content>
        </v-list-item>  
        <v-list-item class="sidebar-list-item divider">
          <v-list-item-action></v-list-item-action>
          <v-list-item-content><v-divider></v-divider></v-list-item-content>
        </v-list-item>
        <v-list-item :to="{name: 'lists', params: {type: 'food'}}" class="sidebar-list-item food" active-class="active">
          <v-list-item-action>
            <v-icon dark>mdi-silverware-fork-knife</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t('food') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="sidebar-list-item divider">
        <v-list-item-action></v-list-item-action>
          <v-list-item-content><v-divider></v-divider></v-list-item-content>
        </v-list-item>	
        <v-list-item :to="{name: 'lists', params: {type: 'drink'}}" class="sidebar-list-item drink" active-class="active">
          <v-list-item-action>
            <v-icon dark>mdi-glass-wine</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t('drink') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="sidebar-list-item divider">
          <v-list-item-action></v-list-item-action>
          <v-list-item-content><v-divider></v-divider></v-list-item-content>
        </v-list-item>
        <!--<v-list-item :to="{name: 'search'}" class="sidebar-list-item search" active-class="active">
          <v-list-item-action>
            <v-icon dark>mdi-magnify</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t('search.drinks') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>-->
        <v-list-item class="sidebar-list-item divider">
          <v-list-item-action></v-list-item-action>
          <v-list-item-content><v-divider></v-divider></v-list-item-content>
        </v-list-item>
      </v-list>

    </v-navigation-drawer>

    <v-app-bar app clipped-left height="68">
      <v-btn icon @click.stop="drawer = !drawer"><v-icon>{{navBarIcon}}</v-icon></v-btn>
      <v-toolbar-title>
        <router-link :to="{ name: 'home' }" class="site-name">
          <v-img src="/images/lukitxene_logo_new.png" :alt="$t('app_title')" class="site-logo" width="150" contain></v-img>
        </router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <Cart action="Fav" />
    </v-app-bar>

    <v-content>
      <vue-page-transition name="fade" class="transition">
        <router-view :key="$route.fullPath" />
      </vue-page-transition>
    </v-content>
  </v-app>
</template>

<script>
  import i18n from '../plugins/i18n'
  import Cart from '../components/Cart'
  
  export default {
    components: {
      Cart
    },
    data: function () {
      return {
        drawer: null,
        langs: ['es', 'eu', 'en']
      }
    },
    computed: {
      navBarIcon(){
        return (this.drawer ? 'mdi-close' : 'mdi-menu')
      }
    },
    mounted(){
      if (localStorage.locale != null) this.$root.$i18n.locale = localStorage.locale;
    },
    methods: {
      changeLocale(lang){
        localStorage.locale = lang
        window.location.reload()
      }
    },
    i18n
  }
</script>

<style>
  :root {
    --overlay-bg: #E2DFDB;
    --transition-duration: .40s;
  }
</style>

<style scoped lang="scss">
  
  @import "../stylesheets/header.scss"
  @import "../stylesheets/sidebar.scss"
</style>
