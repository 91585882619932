<template>
  <v-card tile class="list-card" :to="{name: 'list', params: {id: list.id}}">
    <v-img v-if="hasImages"
      :src="list.images[0].url"
      class="white--text align-end"
      height="160px"
    >
    </v-img>
    <v-card-title v-if="isFood" class="headline" v-text="list.name"></v-card-title>
    <v-card-text v-html="list.description" class="list-description"></v-card-text>
    <v-card-text class="list-notes">
      <v-row justify="end">
        <v-col v-if="list.notes"> 
          <div v-html="list.notes"></div>
        </v-col>
        <v-col v-if="list.sale_price" cols="4">
          <div class="list-price">{{ $n(list.sale_price, 'currency')}}</div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions v-if="actions">
      <v-btn text class="btn-custom" :to="{name: 'list', params: {id: list.id}}">
        <v-icon large color="primary">{{isFree ? 'mdi-glass-wine' : 'mdi-silverware-fork-knife'}}</v-icon>
        <!-- $t('list.show_free') -->
        <span class="display-custom-1">{{ isFree ? list.name : $t('list.show') }}</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import i18n from '../plugins/i18n'
  import ListItem from './ListItem'

  export default {
    props: {
      list: {
        type: Object,
        required: true
      },
      actions: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      hasImages(){
        return this.list != undefined && this.list.images != undefined && this.list.images.length > 0
      },
      isFree(){
        return this.list.list_type === 'free'
      },
      isFood(){
        return this.list.type.indexOf('food') > -1
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../stylesheets/components/list.scss"
</style>
