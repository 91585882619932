<template>
  <v-container fluid class="list-index-container without-padding" :class="type">
    <v-container v-if="type === 'drink'" fluid class="list-header list-index-header drink without-padding">
      <v-row>
        <v-col>
          <h1>{{ $t(this.type) }}</h1>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid class="list-index-content">
      <div v-if="type === 'drink'" class="drink-text index-text" v-html="$t('list.drink_list_text')"></div>
      <v-row>
        <v-col v-if="type === 'drink'" cols="12" sm="6">
          <v-card tile class="list-card list-index" :to="{name: 'search'}">
            <v-img src="/images/home/home-bebe.jpg" height="160" />
            <v-card-text class="list-description"><p>{{ $t('search_wines_card.description') }}</p></v-card-text>
            <v-card-text class="list-notes"></v-card-text>
            <v-card-actions>
              <v-btn text class="btn-custom" :to="{name: 'search'}">
                <v-icon large color="primary">mdi-magnify</v-icon>
                <span class="display-custom-1">{{ $t('search_wines_card.title') }}</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col v-for="list in lists" :key="list.id" :cols="12" :sm="6">
          <List :list="list" class="list-index"/>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>

function getLists(routeTo, next) {
  const type = routeTo.params.type
  store
    .dispatch('list/fetchLists', type).then(() => {
      next()
    })
}

import ListService from '../services/ListService.js'
import List from '../components/List'
import SearchForm from '../components/SearchForm'
import i18n from '../plugins/i18n'
import { mapState } from 'vuex'
import store from '../store/store'

export default {
  i18n,
  store,
  components: {
    List,
    SearchForm
  },
  props: ["type"],
  beforeRouteEnter(routeTo, routeFrom, next) {
    getLists(routeTo, next)
  },
  beforeRouteUpdate(routeTo, routeFrom, next) {
    getLists(routeTo, next)
  },
  computed: mapState('list', ['lists'])
}

</script>

<style scoped lang="scss">
  @import "../stylesheets/views/list_index.scss"
  @import "../stylesheets/components/list.scss"
</style>
