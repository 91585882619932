import '@mdi/font/css/materialdesignicons.css'
import colors from 'vuetify/lib/util/colors'
import Vue from "vue"
import Vuetify from "vuetify/lib"
import light from './theme'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdi',  // 'md' || 'mdiSvg' || 'md' || 'fa' || 'fa4'
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: { light }
  }
})
