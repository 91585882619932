import apiClientv1 from './api_client'
import apiClientv2 from './api_client_v2'

export default {
  getLists(type='food') {
    return apiClientv1.get('/lists', { params: {type: type, locale: localStorage.locale} })
  },
  getList(params){
    return apiClientv2.get('/lists/' + params.id, { params: {page: params.page, locale: localStorage.locale} })
  }
}