<template>
  <div class="add-to-cart" :class="[this.type, this.action]">
    <!-- icon or text -->
    <v-btn :icon="this.type === 'icon'" small @click.prevent="addToCart">
      <v-icon large :color="cartColor">{{cartIcon}}</v-icon>
      <span v-if="this.type === 'btn'" class="add-to-cart-text">{{ $t(this.action + '.add') }}</span>
    </v-btn>
  </div>
</template>

<script>
  import i18n from '../plugins/i18n'
  import store from '../store/store'
  import { mapState } from 'vuex'

  export default {
    store,
    props: ['action', 'type', 'item', 'formatId'],
    computed: {
      isFav(){
        return this.action === 'Fav'
      },
      isCart(){
        return this.action === 'Cart'
      },
      cartIcon(){
        let iconClass = 'mdi-'
        if (this.isFav) {
          iconClass += 'heart'
        } else if (this.isCart) {
          iconClass += 'clipboard-list'
        }
        if (!this.alreadyAdded) {
          iconClass += '-outline'
        }
        return iconClass
      },
      cartColor(){
        if (this.isFav) {
          return 'red'
        } else if (this.isCart) {
          return 'green'
        }
      },
      cartItem(){
        // modify item to include selected formatId
        return {
          id: this.item.id, 
          type: this.item.type, 
          name: this.item.name, 
          images: this.item.images, 
          formats: this.item.formats,
          selected_format_id: this.formatId,
          sale_price: this.item.sale_price
        }
      },
      alreadyAdded(){
        if (this.isFav) {
          // return this.fav_resources.includes(this.cartItem)
          return this.fav_resources.some((x) => x.id === this.cartItem.id && x.type === this.cartItem.type && x.selected_format_id === this.formatId)
        } else if (this.isCart) {
          return this.cart_resources.includes(this.cartItem) != -1
        }
      },
      ...mapState('cart', ['fav_resources', 'cart_resources'])
    },
    methods: {
      addToCart(){
        this.$store.dispatch('cart/addTo' + this.action, this.cartItem).then(_ => {
          // nothing to do
        });
      }
    }
  }
</script>
