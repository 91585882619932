import Vue from 'vue' 
import Router from 'vue-router'
import Home from './views/Home.vue'
import ListIndex from './views/ListIndex.vue'
import ListShow from './views/ListShow.vue'
import IngredientShow from './views/IngredientShow.vue'
import RecipeShow from './views/RecipeShow.vue'
import SearchShow from './views/SearchShow.vue'
import store from './store/store'
// import NProgress from 'nprogress';

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/es',
      name: 'home_es',
      redirect: (to) => {
        localStorage.locale = 'es'
        return "/"
      }
    },
    {
      path: '/eu',
      name: 'home_eu',
      redirect: (to) => {
        localStorage.locale = 'eu'
        return "/"
      }
    },
    {
      path: '/en',
      name: 'home_en',
      redirect: (to) => {
        localStorage.locale = 'en'
        return "/"
      }
    },
    {
      path: '/lists/:type',
      name: 'lists',
      component: ListIndex,
      props: true,
      meta: { transition: 'zoom' }
    },
    {
      path: '/list/:id',
      name: 'list',
      component: ListShow,
      props: true
      // meta: { transition: 'overlay-down-full' }
    },
    {
      path: '/warehouse/:id',
      name: 'warehouse',
      component: ListShow,
      props: route => {
        return {
          id: route.params.id,
          warehouse: true
        }
      },
      beforeEnter(routeTo, routeFrom, next) {
        localStorage.locale = routeTo.query.locale || 'es'
        next()
      }
    },
    {
      path: '/ingredient/:id',
      name: 'ingredient',
      component: IngredientShow,
      props: true,
      beforeEnter(routeTo, routeFrom, next) {
        store.dispatch('ingredient/fetchIngredient', routeTo.params.id).then(ingredient => {
          routeTo.params.ingredient = ingredient
          next()
        })
      }
    },
    {
      path: '/recipe/:id',
      name: 'recipe',
      component: RecipeShow,
      props: true,
      beforeEnter(routeTo, routeFrom, next) {
        store.dispatch('recipe/fetchRecipe', routeTo.params.id).then(recipe => {
          routeTo.params.recipe = recipe
          next()
        })
      }
    },
    {
      path: '/search',
      name: 'search',
      component: SearchShow,
      props: true
    }
  ],
  scrollBehavior() {
    window.scrollTo(0,0);
  }
})

// TOREVIEW
// router.beforeResolve((to, from, next) => {
//   // If this isn't an initial page load.
//   if (to.name) {
//     // Start the route progress bar.
//     NProgress.start()
//   }
//   next()
// })

// router.afterEach((to, from) => {
//   // Complete the animation of the route progress bar.
//   NProgress.done()
// })

export default router
