import SearchService from 'services/SearchService.js'

export const namespaced = true

export const state = {
  criterios: [],
  // sale_price_gteq: null,
  // sale_price_lteq: null,
  results: [], 
  resultsCount: 0,
  facets: [],
  // min_sale_price: 5,
  // max_sale_price: 500,
  loading: false,
  page: 1,
  errors: "",
  sort: '_score',
  title: ""
}

export const mutations = {
  SET_CRITERIOS(state, payload){
    // set criterios from url query params
    state.criterios = []
    // insert parent_title criterios
    if (payload.parent_title != undefined) {
      let criterios = payload.parent_title.split(' AND ');
      for (let i=0; i < criterios.length; i++){
        let criterio = criterios[i].split(': ');
        state.criterios.push({key: criterio[0], value: criterio[1]});
      } 
    }
    // insert current criterio
    if (payload.key != undefined && payload.value != undefined) {
      state.criterios.push({key: payload.key, value: payload.value});
    }
    if (payload.sale_price_gteq != undefined) {
      // consider calling mutation
      state.sale_price_gteq = payload.sale_price_gteq
    }
    if (payload.sale_price_lteq != undefined) {
      // consider calling mutation
      state.sale_price_lteq = payload.sale_price_lteq
    }
  },
  PUSH_CRITERIO(state, payload) {    
    state.criterios.push(payload);
  },
  REMOVE_CRITERIO(state, payload){
    state.criterios = state.criterios.filter(x => x.key != payload.key || x.value != payload.value);
  },
  // SET_SALE_PRICE_GTEQ(state, payload){
  //   state.sale_price_gteq = payload
  // },
  // SET_SALE_PRICE_LTEQ(state, payload){
  //   state.sale_price_lteq = payload
  // },
  SET_RESULTS(state, payload) {
    state.results = payload;
  },
  PUSH_RESULTS(state, payload) {
    payload.forEach(a => {
      state.results.push(a);
    })
  },
  SET_RESULTS_COUNT(state, payload) {
    state.resultsCount = payload;
  },
  SET_FACETS(state, payload) {
    state.facets = payload;
  },
  // SET_MIN_SALE_PRICE(state, payload) {
  //   state.min_sale_price = payload;
  // },
  // SET_MAX_SALE_PRICE(state, payload) {
  //   state.max_sale_price = payload;
  // },
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_ERRORS(state, payload) {
    state.errors = payload;
  }, 
  SET_SORT(state, payload) {
    state.sort = payload;
  },
  SET_PAGE(state, payload) {
    state.page = payload;
  },
  INCREMENT_PAGE(state) {
    state.page += 1;
  }
}

export const actions = {
  getResults({ commit, state }, payload){
    if (state.page === 1) {
      commit('SET_LOADING', true);
      commit('SET_CRITERIOS', payload);
    }

    return new Promise((resolve, reject) => 
      // , sale_price_gteq: state.sale_price_gteq, sale_price_lteq: state.sale_price_lteq
      new SearchService.getSearchDrinks({page: state.page, sort: state.sort, ...payload})
      .then(response => {
        if (state.page === 1) {
          commit('SET_RESULTS', response.data.results);
          commit('SET_RESULTS_COUNT', response.data.results_count);
          commit('SET_FACETS', response.data.facets);
          // commit('SET_MIN_SALE_PRICE', state.sale_price_gteq || response.data.min_sale_price);
          // commit('SET_MAX_SALE_PRICE', state.sale_price_lteq || response.data.max_sale_price);  
        } else {
          commit('PUSH_RESULTS', response.data.results);
        }
        resolve(response);
        commit('SET_LOADING', false);
      })
      .catch(error => {
        commit('SET_ERRORS', error.response)
        commit('SET_LOADING', false)
        reject(error)
      })
    )
  },
  getNextPage({ commit, dispatch, getters }, payload){
    return new Promise((resolve, reject) => {
      commit('INCREMENT_PAGE');
      dispatch('getResults', getters.getSearchParams).then(response => {
        resolve(response);
      });
    });
  },
  // setSalePrice({ commit }, payload){
  //   return new Promise((resolve, reject) => {
  //     commit('SET_SALE_PRICE_GTEQ', payload[0]);
  //     commit('SET_SALE_PRICE_LTEQ', payload[1]);
  //     commit('SET_PAGE', 1);
  //     resolve();
  //   });
  // },
  setSortOrder({ commit, dispatch, getters }, payload){
    return new Promise((resolve, reject) => {
      commit('SET_SORT', payload)
      dispatch('getResults', getters.getSearchParams).then(response => {
        resolve(response);
      });
    })
  },
  addCriterio({ commit }, payload){
    return new Promise((resolve, reject) => {
      commit('PUSH_CRITERIO', payload);
      commit('SET_PAGE', 1);
      resolve();
    });
  },
  removeCriterio({ state, commit }, payload){
    return new Promise((resolve, reject) => {
      let index = state.criterios.indexOf(payload);
      if (index > -1) {
        commit('REMOVE_CRITERIO', payload);
        commit('SET_PAGE', 1);
        resolve();
      }
    });
  },
  resetSearch({ commit, dispatch }, payload){
    return new Promise((resolve, reject) => {
      commit('SET_PAGE', 1);
      // dispatch('setSalePrice', [null, null])
      dispatch('getResults', []).then(response => {
        resolve(response);
      });
    });
  }
}

export const getters = {
  getParentTitle: (state) => {
    // returns string
    // compress all criterios joined by AND
    let parent_title = []
    for (let i = 0; i < state.criterios.length; i++) {
      let criterio = state.criterios[i]
      parent_title.push(`${criterio.key}: ${criterio.value}`)
    }
    return parent_title.join(' AND ')
  },
  getSearchParams: (state) => {
    // returns object
    // {key: :last_criterio_key, value: :last_criterio_value, parent_title: :the_rest}
    let search_params = {}
    let copy = state.criterios.slice()
    let last_criterio = copy.pop()
    if (last_criterio != undefined) {
      search_params.key = last_criterio.key
      search_params.value = last_criterio.value
    }
    let parent_title = []
    copy.forEach(criterio => {
      parent_title.push(`${criterio.key}: ${criterio.value}`)
    })
    if (parent_title.length > 0) {
      search_params.parent_title = parent_title.join(' AND ')
    }
    return search_params
  },
  getSearchQuery: (state, getters) => {
    // returns string
    // current criterio in key/value, rest in parent_title
    // add sale_price_gteq & sale_price_lteq if present
    let search_query = []
    Object.entries(getters.getSearchParams).forEach(([k,v]) => {
      search_query = search_query.concat(`${k}=${v}`)
    })
    // if (state.sale_price_gteq !== null) {
    //   search_query = search_query.concat(`sale_price_gteq=${state.sale_price_gteq}`)
    // }
    // if (state.sale_price_lteq !== null) {
    //   search_query = search_query.concat(`sale_price_lteq=${state.sale_price_lteq}`)
    // }
    return search_query.join('&')
  }
}
