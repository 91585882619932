import colors from 'vuetify/lib/util/colors'

export default {
  primary: {
    base: "#786859", //titulos ficha cards marron medio antes #998675
    darken1: '#271900', // fondo boton
    lighten1: '#E2DFDB', // boton desactivado
    lighten2: '#EEECEA', // texto boton, lineas, fondo body
    lighten3: '#E3DFDA' // bg search col left
  },
  secondary: {
    base: '#828282', // d.o.ingredient subtitle
    darken1: '#4F4F4F', // texto corrido
    lighten1: '#BDBDBD', //?
    lighten2: '#F8F7F7'  //fondo desplegado
  },
  text: {
    base: '#4F4F4F',
    title: '#998675'
  },
	red: {
		base: '#9C0000'
	},
  tertiary: {
    base: '#E2DFDB',
    pasive: '#E2DFDB',
    active: '#D70000',
    favorite: '#D70000',
    command: '#39B54A'
  }
}
