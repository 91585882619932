<template>
  <v-container fluid class="ingredient-show-container without-padding">
    <Ingredient :ingredient="ingredient" />
  </v-container>
</template>

<script>
  import Ingredient from '../components/Ingredient'
  
  export default {
    components: {
      Ingredient
    },
    props: {
      ingredient: {
        type: Object,
        required: true
      }
    }
  }
</script>

<style scoped>
  
</style>
