<template>
  <div class="nutritional-info">
    <v-expansion-panels v-model="panel" multiple>
      <v-expansion-panel :disabled="panel_disabled">
        <v-expansion-panel-header>
          <v-subheader>{{ $t('nutritional_info.title') }}</v-subheader>
        </v-expansion-panel-header>
	<v-divider></v-divider>
        <v-expansion-panel-content eager>
          <v-container fluid>
            <v-row class="featured">
              <v-col v-for="value in featured_values" :key="value[0]" cols="6" sm="3">
                <div class="nutritional-attr">
                  <span class="label">{{ $t('nutritional_info.' + value[0] + '.label') }}</span>
                  <span class="value">{{ $t('nutritional_info.' + value[0] + '.value', { value: value[1] })}}</span>
                </div>
              </v-col>
            </v-row>
            <v-row v-if="other_values.length > 0">
              <v-col v-for="value in other_values":key="value[0]" cols="6">
                <div class="nutritional-attr" :class="$vuetify.breakpoint.xsOnly ? 'xs' : ''">
                  <span class="label">{{ $t('nutritional_info.' + value[0] + '.label') }}</span>
                  <span class="value">{{ $t('nutritional_info.' + value[0] + '.value', { value: value[1] })}}</span>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
  import i18n from '../plugins/i18n'
  export default {
    i18n,
    props: {
      values: {
        type: Object
      },
      container: {
        type: String,
        default: 'Ingredient'
      }
    },
    data () {
      return {
        panel: [0],
        panel_disabled: true
      }
    },
    computed: {
      array_values(){
        return Object.entries(this.values)
      },
      featured_values(){
        return this.array_values.slice(0,4)
      },
      other_values(){
        return this.array_values.slice(4)
      }
    },
    created(){
      if (this.container === 'Recipe') {
        this.panel = []
        this.panel_disabled = false
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../stylesheets/components/nutritional_info.scss"
</style>
