<template>
  <v-list-item :class="item.type | toLowerCase">
    <v-list-item-content>
      <v-row>
        <v-col @click="showDialog" :cols="showCheckbox ? ($vuetify.breakpoint.xsOnly ? '12' : '11') : (showPriceAndFormats ? ($vuetify.breakpoint.xsOnly ? 'undefined' : '10') : '12')" justify="space-between">
          <v-row>
            <v-col v-if="itemImage != null" cols="12" sm="3" class="image">
              <v-img :src="thumbItemImage" cover :class="$vuetify.breakpoint.xsOnly ? 'xs' : ''"></v-img>
            </v-col>
            <v-col class="title" :cols="showCheckbox ? '9' : '11'" sm="8" align-self="center">
              <div class="resource-name subtitle">
                {{ item.name }}<span v-if="formats && shownFormatNotDefault && !item.selected_format_id">, {{ shownFormat.name | to_locale }}</span>
                <v-img v-if="itemType === 'Recipe' && item.in_season" :src="`/images/icons/season.png`" :width="15" :height="15" class="season" />
                <v-img v-if="itemType === 'Recipe' && item.vegan" :src="`/images/icons/vegan.png`" :width="21" :height="21" class="vegan" />
                <v-img v-if="itemType === 'Recipe' && item.vegetarian" :src="`/images/icons/vegetarian.png`" :width="14" :height="14" class="vegetarian" />
              </div>
              
              <Allergens v-if="itemType === 'Recipe'" :values="item.allergens" :targetView="'ListItem'" />
            </v-col>
            <v-col cols="1" class="progress">
              <v-progress-circular v-if="loading" size="50" color="primary darken-1" :indeterminate="loading"></v-progress-circular>
            </v-col>
            <v-col v-if="showCheckbox && $vuetify.breakpoint.xsOnly" cols="1" class="checkbox">
              <v-checkbox v-model="checked" checked color="primary darken-1" value="item.id" hide-details class="list-item" @click.stop="toggleChecked"></v-checkbox>
            </v-col>
          </v-row>
        </v-col>
        <v-col v-if="showCheckbox && !$vuetify.breakpoint.xsOnly" cols="1" class="checkbox">
          <v-checkbox v-model="checked" color="primary darken-1" value="item.id" hide-details class="list-item"></v-checkbox>
        </v-col>
        <template v-else-if="showPriceAndFormats">
          <v-col class="price">
            <span class="item-sale-price" v-html="salePrice"></span>
          </v-col> 
        </template>      	
        <slot></slot>
      </v-row>
    </v-list-item-content>
    <!-- v-touch="{ left: () => goToNext, right: () => goToPrev }" -->
    <v-dialog v-model="dialog" :fullscreen="this.$vuetify.breakpoint.xsOnly" content-class="list-item">
      <v-btn :color="iconColor" text class="dialog-close" @click="dialog = false">
        <v-icon left>mdi-close</v-icon>
      </v-btn>
      <v-btn v-if="prevAvailable" @click="goToPrev" :color="iconColor" fab text icon class="dialog-nav dialog-prev">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-btn v-if="nextAvailable" @click="goToNext" :color="iconColor" fab text icon class="dialog-nav dialog-next">
        <v-icon>mdi-arrow-right</v-icon>
      </v-btn>
      <!-- <v-card tile v-if="item_loading" height="700">
        <v-skeleton-loader class="mx-auto" type="image, article, actions" tile></v-skeleton-loader>
      </v-card> -->
      <v-progress-circular v-if="item_loading" size="80" color="primary" :indeterminate="item_loading" class="item_progress"></v-progress-circular>
      <Recipe v-if="itemType === 'Recipe'" :recipe="fullItem" />
      <Ingredient v-else-if="itemType === 'Ingredient'" :ingredient="fullItem" />
      <List v-if="itemType === 'List'" :list="fullItem"/>
    </v-dialog>
  </v-list-item>
</template>

<script>
  import i18n from '../plugins/i18n'
  import { mapState } from 'vuex'
  import store from '../store/store'
  import Recipe from './Recipe'
  import Allergens from './Allergens'
  import Ingredient from './Ingredient'
  import List from './List'

  export default {
    i18n,
    store,
    components: {
      Recipe,
      Allergens,
      Ingredient,
      List
    },
    props: {
      item: {
        type: Object,
        required: true
      },
      targetView: {
        type: String,
        default: 'list'
      },
      choose: {
        type: Number, 
        default: null
      }
    },
    data () {
      return {
        dialog: false,
        loading: false,
        item_loading: false,
        fullItem: Object,
        checked: false
      }
    },
    computed: {
      showCheckbox(){
        // this.targetView === 'list' && this.choose != null
        return false
      },
      showPriceAndFormats(){
        return (this.list.list_type === 'free' || this.targetView !== 'list') && this.salePrice != null
      },
      iconColor(){
        return this.fullItem.type === 'Ingredient' ? 'grey' : 'white'
      },
      itemType(){
        return this.dialog ? this.fullItem.type : this.item.type
      },
      itemImage(){
        if (this.item.image != undefined) {
          return this.item.image
        } else if (this.item.images != undefined && this.item.images.length > 0) {
          return this.item.images[0].url
        } else {
          return null
        }
      },
      thumbItemImage(){
        if (this.itemImage != null) {
          let basename = this.itemImage.substr(this.itemImage.lastIndexOf('/') + 1)
          let thumb = (basename.match(/^w[37]00r?_/) != null) ? this.itemImage : this.itemImage.replace(basename, "w300_" + basename);
          // if (this.item.type === 'Ingredient') {
          //   thumb = thumb.replace(/w300_/, 'w300r_')
          // }
          return thumb
        }
      },
      formats(){
        return this.item.formats != undefined && this.item.formats.length > 0
      },
      defaultFormat(){
        // format "Botella" (usually id#6, 0.75 l)
        return this.item.formats.find(x => x.id === 6) || this.item.formats.find(x => x.name.match(/0\.75/))
      },
      glassFormat(){
        // format "Copa" (usually id#66, 0.125 l)
        return this.item.formats.find(x => x.id === 66) || this.item.formats.find(x => x.name.match(/0\.125/))
      },
      matchedFormat(){
        let filtered_formats = this.item.formats
        // filter if format selected
        let format_criterio = this.criterios.find(x => x.key === 'formats')
        if (format_criterio != undefined) {
          filtered_formats = filtered_formats.filter(x => x.name === format_criterio.value)
        }
        // filter if sale_price selected
        let price_criterio = this.criterios.find(x => x.key === 'sale_price')
        if (price_criterio != undefined) {
          let price = price_criterio.value.match(/(?<from>.*)€ - (?<to>.*)€/).groups
          if (price.from) {
            filtered_formats = filtered_formats.filter(x => x.sale_price >= parseFloat(price.from))
          }
          if (price.to) {
            filtered_formats = filtered_formats.filter(x => x.sale_price <= parseFloat(price.to))
          } 
        }
        return filtered_formats[0]
      },
      shownFormat(){
        if (this.item.formats != undefined && this.item.formats.length > 0) {
          if (this.item.selected_format_id != undefined) {
            return this.item.formats.find(x => x.id === this.item.selected_format_id)
          } else if (this.matchedFormat != undefined) {
            return this.matchedFormat
          } else if (this.defaultFormat != undefined) {
            return this.defaultFormat
          } else {
            return this.item.formats[0]
          }
        }
      },
      shownFormatNotDefault(){
        return !(this.shownFormat.id == 6 || this.shownFormat.name.match(/Botella/))
      },
      salePrice(){
        if (this.itemType === 'Ingredient'){
          if (this.item.formats != undefined && this.item.formats.length > 1) {
            return this.item.formats.map(x => i18n.n(x.sale_price, 'currency')).join('<hr />')
          } else if (this.shownFormat){
            return i18n.n(this.shownFormat.sale_price, 'currency')
          }
        } else {
          return i18n.n(this.item.sale_price, 'currency')
        }
      },
      itemsCollection(){
        if (this.targetView === 'list') {
          return this.list_items
        } else if (this.targetView === 'search') {
          return this.results
        } else if (this.targetView === 'cart') {
          return this.fav_resources
        }
      },
      itemIndex(){
        return this.itemsCollection.findIndex(x => x.id == this.fullItem.id && x.type === this.fullItem.type)
      },
      prev(){
        return this.itemsCollection[this.itemIndex - 1]
      },
      next(){
        return this.itemsCollection[this.itemIndex + 1]
      },
      prevAvailable(){
        return this.prev != undefined
      },
      nextAvailable(){
        return this.next != undefined
      },
      ...mapState('list', ['list', 'list_items']),
      ...mapState('search', ['results', 'criterios', 'sale_price_gteq', 'sale_price_lteq']),
      ...mapState('cart', ['fav_resources'])
    },
    methods: {
      showDialog(){
        this.loading = true
        this.fetchFullItem(this.item).then(item => {
          this.fullItem = item
          this.dialog = true
          this.loading = false
        })
      },
      fetchFullItem(item){
        return this.$store.dispatch(item.type.toLowerCase() + '/fetch' + item.type, {id: item.id, service: item.service || 'carta'})
      },
      goToPrev(){
        this.item_loading = true
        this.fetchFullItem(this.prev).then(item => {
          this.item_loading = false
          this.fullItem = item
        });
      },
      goToNext(){
        this.item_loading = true
        this.fetchFullItem(this.next).then(item => {
          this.item_loading = false
          this.fullItem = item
        });
      },
      toggleChecked(){
        this.checked = !this.checked
      }
    },
	  filters:{
		  toLowerCase(value){
		    return value.toLowerCase()
		  }
	  }
  }
</script>

<style scoped lang="scss">
  @import "../stylesheets/components/list_item.scss"
</style>
