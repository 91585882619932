<template>
  <v-container fluid class="search-show-container">
      <v-row>
        <!-- only for mobile -->
        <v-navigation-drawer v-model="facets_drawer" class="d-flex d-sm-none" absolute clipped temporary width="300">
          <SearchFilters />
        </v-navigation-drawer>
        <v-col sm="4" class="hidden-xs-only">
          <template v-if="criterios.length > 0 || sale_price_gteq != null || sale_price_lteq != null">
            <div class="search-aside-title">{{ $t('search.criterios') }}</div>
            <SearchCriterios />
          </template>
          <SearchFilters />
        </v-col>
        <v-col cols="12" sm="8">
          <v-row class="d-flex d-sm-none">
            <v-col>
              <SearchCriterios />
            </v-col>
          </v-row>
          <v-row justify="end">
            <v-col cols="5">
              <v-btn @click.stop="facets_drawer = !facets_drawer" class="filters-search d-flex d-sm-none" color="primary">
                <v-icon color="white">mdi-filter</v-icon>
                <span>{{ $t('search.filters') }}</span>
              </v-btn>
            </v-col>
            <v-col cols="7" sm="6" class="text-right">
              <v-btn v-if="currentSearch" @click.prevent="resetSearch" class="new-search" color="primary darken-1">
                <v-icon color="white">mdi-magnify</v-icon>
                <span>{{ $t('search.new') }}</span>
              </v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <div class="search-results-count">
                <span v-if="loading">{{ $t('search.searching') }}</span>
                <span v-else>{{ $tc('search.results', resultsCount) }}</span>
              </div>
            </v-col>
            <v-col cols="5">
              <v-select v-model="sort_order" :items="[{value: 'sale_price_asc', text: $t('search.sort.sale_price_asc')},{value: 'sale_price_desc', text: $t('search.sort.sale_price_desc')}, {value: '_score', text: $t('search.sort.score')}]" :label="$t('search.sort.title')" @change="setSortOrder"></v-select>
            </v-col>
          </v-row>

          <v-skeleton-loader v-if="loading" class="mx-auto" type="list-item-avatar,list-item-avatar,list-item-avatar,list-item-avatar,list-item-avatar" tile></v-skeleton-loader>
          <template v-else>
            <ListItem v-for="result in results" :key="result.id" :item="result" :target-view="'search'"/>
            <infinite-loading @infinite="nextPage">
              <div slot="no-more">{{ $t('search.no_more') }}</div>
              <div slot="no-results">{{ $t('search.no_results') }}</div>
            </infinite-loading>  
          </template>
          
        </v-col>
      </v-row>
  </v-container>
</template>

<script>
  import SearchCriterios from '../components/SearchCriterios'
  import SearchFilters from '../components/SearchFilters'
  import ListItem from '../components/ListItem'
  import i18n from '../plugins/i18n'
  import InfiniteLoading from 'vue-infinite-loading'; 
  import { mapState } from 'vuex'
  import { mapGetters } from 'vuex'
  import store from '../store/store'

  export default {
    i18n,
    store,
    components: {
      ListItem,
      SearchCriterios,
      SearchFilters,
      InfiniteLoading
    },
    data(){
      return {
        page: 1,
        sort_order: '_score',
        facets_drawer: false
      }
    },
    computed: {
      currentSearch(){
        return (this.criterios.length > 0);
      },
      ...mapState('search', ['criterios', 'sale_price_gteq', 'sale_price_lteq', 'results', 'resultsCount', 'errors', 'loading', 'sort']),
      ...mapGetters('search', ['getSearchQuery'])
    },
    watch: {
      // call again the method if the route changes
      '$route': 'fetchSearch'
    },
    created(){
      this.sort_order = this.sort
      this.fetchSearch()
    },
    methods: {
      resetSearch(){
        store.dispatch('search/resetSearch').then(_ => {
          this.$router.push("/search");
        });
      },
      nextPage($state){
        if (!this.loading) {
          if (this.resultsCount === 0) {
            $state.complete();
          } else if (this.resultsCount > this.results.length) {
            store.dispatch('search/getNextPage').then(response => {
              if (response.data.results.length == 0) {
                $state.complete();
              } else  {
                $state.loaded();
              }
            });
          } else {
            $state.loaded();
            $state.complete();
          }
        }
      },
      setSortOrder(){
        store.dispatch('search/setSortOrder', this.sort_order).then(response => {
          // nothing to do
        })
      },
      fetchSearch(){
        store.dispatch('search/getResults', this.$route.query).then(response => {
          // nothing to do
        });
      }
    }

  }
</script>

<style scoped lang="scss">
  @import "../stylesheets/views/search_show.scss"
</style>
