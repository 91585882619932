<template>
  <v-container fluid class="list-show-container without-padding" :class="list.list_type" :id="`list_${list.id}`">
    <v-skeleton-loader v-if="loading" class="mx-auto" type="image, article, actions" tile></v-skeleton-loader>
    <template v-else>
      <v-container fluid class="list-header list-show-header without-padding" :key="`listh_${list.id}`">
        <v-img v-if="coverImage" :src="coverImage.url" class="list-header-bg" />
        <v-row>
          <v-col>
            <h1 :style="{filter: 'opacity(1)'}">{{ list.name }}</h1>
          </v-col>
          <v-col v-if="list.type.includes('drink') && !warehouse" cols="6">
            <div class="drink-text search-text" v-html="$t('list.drink_search_text')"></div>
            <SearchForm :reset="true" />
          </v-col>
        </v-row>
      </v-container>
      <v-container fluid class="list-show-subheader" v-if="list.description || list.notes || list.sale_price" :key="`listsh_${list.id}`">
        <v-row justify="end">
          <v-col cols="12">
            <div v-html="list.description" class="list-description"></div>
          </v-col>
          <v-col cols="8">
            <div v-html="list.notes" class="list-notes"></div>
          </v-col>
          <v-col v-if="list.sale_price" cols="4">
            <div class="list-price text-right">{{ $n(list.sale_price, 'currency') }}</div>
          </v-col>
        </v-row>
        <!--<v-row justify="end">
          <v-col v-if="list.sale_price" cols="4">
            TODO: add to cart not to fav
            <AddToCart action="Fav" type="btn" :item="cartItem" />
          </v-col>
        </v-row>-->
      </v-container>
      <v-container fluid class="list-show-content" :key="`listc_${list.id}`">
        <v-row dense justify="center">
          <v-col>
            <ListCategories v-if="list.list_categories.length > 0" :categories="list.list_categories" :initPanel="panel"/>
            <div v-else-if="list_items">
              <ListItem v-for="(child,i) in list_items" :key="child.id" :item="child" />
              <Pagination :list="list" />
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-if="list.type.includes('food')" fluid class="allergens-legend recipes-legend mb-3">
        <v-row>
          <v-list class="allergens legend-items">
            <v-list-item-group>
              <v-list-item v-for="attr in ['season', 'vegan', 'vegetarian']" :key="attr" class="ma-2" label inactive>
                <v-img contain :src="`/images/icons/${attr}.png`" :width="30" :class="attr" />
                <span>{{ $t('recipe.' + attr) }}</span>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-row>
        <v-row >
          <Allergens :values="['gluten', 'milk', 'eggs', 'soy', 'nuts', 'peanuts', 'sesame', 'celery', 'sulfites', 'fish', 'molluscs', 'crustaceans', 'mustard', 'lupins']" :targetView="'Legend'" />
        </v-row>  
      </v-container>
    </template>
  </v-container>
</template>

<script>
  import ListService from '../services/ListService.js'
  import List from '../components/List'
  import ListCategories from '../components/ListCategories'
  import ListCategory from '../components/ListCategory'
  import ListItem from '../components/ListItem'
  import Allergens from '../components/Allergens'
  import SearchForm from '../components/SearchForm'
  import AddToCart from '../components/AddToCart'
  import Pagination from '../components/Pagination'
  import i18n from '../plugins/i18n'
  import store from '../store/store'
  import { mapState } from 'vuex'

  export default {
    i18n,
    store,
    components: {
      List,
      ListCategories,
      ListCategory,
      ListItem,
      Allergens,
      SearchForm,
      AddToCart,
      Pagination
    },
    props: {
      id: {
        required: true
      },
      warehouse: {
        type: Boolean, 
        default: false
      }
    },
    watch: {
      $route(routeTo, routeFrom) {
        fetchList()
      }
    },
    created(){
      this.fetchList()
    },
    computed: {
      panel: {
        get(){
          if ((this.list != undefined && this.list.list_categories != undefined)) {
            return (this.list.list_type === 'free') ? [] : [...Array(this.list.list_categories.length).keys()]
          } else {
            return []
          }
        },
        set(value){
          return value
        }
      },
      coverImage(){
        if (this.list.images.length > 0) {
          return this.list.images[0]
        }
      },
      cartItem(){
        return Object.assign(this.list, {type: 'List'})
      },
      ...mapState('list', ['list', 'list_items', 'loading'])
    },
    methods: {
      fetchList(){
        store.dispatch('list/fetchList', {id: this.id, warehouse: this.warehouse}).then(_ => {
          // nothing to do
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../stylesheets/components/allergens.scss"
  @import "../stylesheets/views/list_show.scss"
</style>
