<template>
  <v-card class="recipe-card">
    <v-img v-if="coverImage" :src="bigImage" />
    <v-card-title>
      <v-row>
        <v-col class="title">
          {{ recipe.name }}
          <v-img v-if="recipe.in_season" :src="`/images/icons/season.png`" :width="20" :height="20" class="season" />
          <v-img v-if="recipe.vegan" :src="`/images/icons/vegan.png`" :width="30" :height="30" class="vegan" />
          <v-img v-if="recipe.vegetarian" :src="`/images/icons/vegetarian.png`" :width="22" :height="22" class="vegetarian" />
        </v-col>
        <v-col v-if="recipe.sale_price" cols="2" class="price">
          <span class="item-sale-price" >{{ $n(recipe.sale_price, 'currency') }}</span>
        </v-col>
        <v-col cols="1" class="cart"><AddToCart action="Fav" type="icon" :item="recipe" /></v-col>
      </v-row>
    </v-card-title>

    <v-btn v-if="recipe.pairing_query.length > 0" color="primary darken1" text-color="primary lighten2" @click="pairingSearch" style="float:right;margin-right: 2em">
      <v-icon large>mdi-magnify</v-icon>
      <span>Maridaje</span>
    </v-btn>
    
    <template v-if="recipe.description">
      <v-card-text v-html="recipe.description"></v-card-text>
      <v-divider></v-divider>
    </template>
    
    <Allergens :values="recipe.allergens" />

    <NutritionalInfo :values="recipe.nutritional_info" container="Recipe" />
  </v-card>
</template>

<script>
  import i18n from '../plugins/i18n'
  import big_image_mixin from '../mixins/big_image'
  import Allergens from '../components/Allergens'
  import NutritionalInfo from '../components/NutritionalInfo'
  import AddToCart from '../components/AddToCart'
  
  export default {
    i18n,
    components: {
      Allergens,
      NutritionalInfo,
      AddToCart
    },
    mixins: [big_image_mixin],
    props: {
      recipe: {
        type: Object,
        required: true
      }
    },
    computed: {
      coverImage(){
        return ((this.recipe.images.length > 0) ? this.recipe.images[0].url : undefined)
      }
    },
    methods: {
      pairingSearch(){
        this.$router.push("/search?" + this.recipe.pairing_query);
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../stylesheets/components/recipe.scss"
</style>
