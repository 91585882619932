<template>
  <v-container fluid fill-height class="home-container without-padding">
    <v-container fluid class="home-header without-padding">
      <v-img src="/images/cabecera-home.png" class="home-header-bg" />
      <v-row>
        <v-col><h1>Ongi etorri</h1></v-col>
      </v-row>
    </v-container>
    <v-container fluid class="home-content without-padding">
      <!--<v-row>
        <v-col>
          <div class="home-text-1">{{ $t('home.welcome_text_1') }}</div>
          <div class="home-text-2">{{ $t('home.welcome_text_2') }}</div>
        </v-col>
      </v-row>-->
      <v-row>
	      <v-col cols="12" sm="6">
      		<v-card tile class="home-cart food-card" :to="{name: 'lists', params: {type: 'food'}}">
            <v-icon large color="primary lighten1">mdi-silverware-fork-knife</v-icon>
            <span class="display-custom-2">{{ $t('home.food') }}</span>
          </v-card>
	      </v-col>
	      <v-col cols="12" sm="6">
		      <v-card tile class="home-cart drink-card" :to="{name: 'lists', params: {type: 'drink'}}">
			      <v-icon large color="primary lighten1">mdi-glass-wine</v-icon>
            <span class="display-custom-2">{{ $t('home.drink') }}</span>
          </v-card>
	      </v-col>
      </v-row>
    </v-container>
    <v-container fluid class="home-footer without-padding"></v-container>
  </v-container>  
</template>

<script>
  import i18n from '../plugins/i18n'
  export default {
    i18n
  }
</script>

<style scoped lang="scss">
  @import "../stylesheets/views/home.scss"
</style>
