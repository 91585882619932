<template>
  <v-dialog v-model="dialog" :id="cartId" :fullscreen="this.$vuetify.breakpoint.xsOnly" :ref="cartId" content-class="cart">
    <template v-slot:activator="{ on }">
      <v-badge overlap :color="cartColor" class="align-self-right">
        <template v-if="!isEmpty" v-slot:badge>
          <span>{{ resources.length }}</span>
        </template>
        <v-icon large :color="cartColor" v-on="on">{{ cartIcon }}</v-icon>
      </v-badge>
    </template>
    <v-btn color="white" text class="dialog-close" @click="dialog = false">
      <v-icon left>mdi-close</v-icon>
    </v-btn>
    <v-container class="cart-container" :class="action">
      <v-container fluid class="cart-header without-padding">
        <v-img src="/images/cabecera-favoritos.png" class="cart-header-bg" />
        <v-row>
          <v-col>
            <h1>{{ $t(action + ".title") }}</h1>
          </v-col>
        </v-row>
      </v-container>
      <template v-if="resources.length > 0">
        <v-container fluid class="cart-content">
          <v-list>
            <ListItem v-for="(resource,i) in resources" :key="resource.id" :item="resource" :target-view="'cart'" >
              <v-col cols="1" justify="end" class="icons">
                <v-btn icon small @click.prevent="removeFromCart(resource)" title="Eliminar">
                  <v-icon large color="red">mdi-delete-outline</v-icon>
                </v-btn>
              </v-col>
            </ListItem>
          </v-list>
        </v-container>
        <v-container fluid class="cart-footer without-padding">
          <v-row>
            <v-col cols="6">
              <v-btn v-if="!isEmpty" color="red" @click.prevent="emptyCart">
                <v-icon color="white">mdi-delete</v-icon>
                <span>{{ $t(action + ".empty") }}</span>
              </v-btn>
            </v-col>
            <!--<v-col cols="6">
              <v-btn v-if="!isEmpty && isFav" color="green" @click.prevent="addToCart">
                <v-icon color="white">mdi-clipboard-list</v-icon>
                <span>{{ $t('Cart.add') }}</span>
              </v-btn>
	    </v-col>-->
          </v-row>
        </v-container>
      </template>
      <v-container v-else class="home-container">
        <v-container fluid class="home-content without-padding">
          <v-row>
            <v-col>
              <div class="home-text-1">{{ $t(action + '.empty_text_1') }}</div>
              <div class="home-text-2">{{ $t(action + '.empty_text_2') }}</div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <v-card tile class="home-cart food-card" @click="goTo('/lists/food')">
                <v-icon large color="primary lighten1">mdi-silverware-fork-knife</v-icon>
                <span class="display-custom-2">{{ $t('home.food') }}</span>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6">
              <v-card tile class="home-cart drink-card" @click="goTo('/lists/drink')">
                <v-icon large color="primary lighten1">mdi-glass-wine</v-icon>
                <span class="display-custom-2">{{ $t('home.drink') }}</span>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-container>
    </v-container>
  </v-dialog>
</template>

<script>
  import i18n from '../plugins/i18n'
  import ListItem from './ListItem.vue'
  import { mapState } from 'vuex'
  import store from '../store/store'

  import Axios from 'axios';
  let csrfToken = document.head.querySelector('meta[name=csrf-token]').content;
  Axios.defaults.headers.post['X-CSRF-Token'] = csrfToken;

  export default {
    store,
    components: {
      ListItem
    },
    props: {
      action: {
        default: 'Cart'
      }
    },
    data () {
      return {
        dialog: false,
      }
    },
    computed: {
      isFav(){
        return this.action === 'Fav'
      },
      isCart(){
        return this.action === 'Cart'
      },
      cartIcon(){
        let iconClass = 'mdi-'
        if (this.isFav) {
          iconClass += 'heart'
        } else if (this.isCart) {
          iconClass += 'clipboard-list'
        }
        if (this.isEmpty) {
          iconClass += '-outline'
        }
        return iconClass
      },
      cartColor(){
        if (this.isFav) {
          return 'red'
        } else if (this.isCart) {
          return 'green'
        }
      },
      cartId(){
        if (this.isFav) {
          return 'fav-modal'
        } else if (this.isCart) {
          return 'cart-modal'
        }
      },
      resources(){
        if (this.isFav) {
          return this.fav_resources;
        } else if (this.isCart) {
          return this.cart_resources;
        }
      },
      isEmpty(){
        return (this.resources.length == 0);        
      },
      ...mapState('cart', ['fav_resources', 'cart_resources'])
    },
    methods: {
      emptyCart(){
        // $t('cart.clean_are_you_sure')
        if (confirm('¿Está seguro que desea limpiar la selección?')){
          this.$store.dispatch('cart/reset' + this.action).then(_ => {
            this.dialog = false
          });  
        }
      },
      removeFromCart(resource){
        this.$store.dispatch('cart/addTo' + this.action, Object.assign(resource, {confirm: true})).then(_ => {
          // nothing to do
        });
      },
      addToCart(){
        alert('TODO: AÑADIR TODOS A COMANDA')
      },
      goTo(path) {
        if (this.dialog) {
          this.dialog = false
          this.$router.push(path);
        } else {
          this.$router.push(path);
        }
      }
    },
    mounted() { 
      this.$root.$i18n.locale = document.body.getAttribute('data-locale');
      // move cart_modal to end of body, because it doesn't work in navbar
      // document.body.appendChild(this.$refs[this.cartId]);
      // retrieve cart items
      if (localStorage.getItem(this.action + '_resources')) {
        let stored = JSON.parse(localStorage.getItem(this.action + '_resources'));
        stored.map((item) => { 
          this.$store.dispatch('cart/addTo' + this.action, item).then(_ => {
            // nothing to do
          }); 
        });
      }
    }

  }
</script>

<style scoped lang="scss">
  @import "../stylesheets/components/cart.scss"
  @import "../stylesheets/views/home.scss"
</style>
